<!-- 卖卡记录 -->
<template>
    <div class="w100">
        <header>
            <el-form :inline="true" :model="filterObj" class="demo-form-inline">
                <el-form-item>
                    <el-select v-model="reqCardTypeObj.gc_type" placeholder="卡类">
                        <el-option :label="item.text" v-for="item in cardSpeciesList" :key="item.value"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="filterObj.gc_id" placeholder="卡种">
                        <el-option :label="item.gc_name" v-for="item in cardTypeList" :key="item.gc_id"
                            :value="item.gc_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="filterObj.usr_status" placeholder="状态" clearable>
                        <el-option :label="item.label" v-for="item in usr_statusList" :key="item.value"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-date-picker v-model="filterDate" type="daterange" align="right" unlink-panels range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                </el-date-picker>
                <el-form-item>
                    <el-button type="primary" @click="getList">查询</el-button>
                </el-form-item></el-form>
        </header>
        <el-table border :data="list" style="width: 90%" v-loading="loading">
            <el-table-column prop="g_cost" label="面值">
            </el-table-column>
            <el-table-column prop="card_num" label="卡号">
            </el-table-column>
            <el-table-column prop="card_data" label="卡密">
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span
                        :style="{ color: scope.row.usr_status == 0 ? '#0882ea' : scope.row.usr_status == 1 ? '#04d932' : '#ff3e13' }">{{
                            scope.row.usr_status == 0
                            ? "审核中"
                            : scope.row.usr_status == 1
                                ? "已通过"
                                : "已驳回"
                        }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="usr_remark" label="备注">
            </el-table-column>
            <el-table-column prop="usr_addtime" label="提交时间">
                <template slot-scope="scope">
                    <span>{{ scope.row.usr_addtime | ts2Date() }}</span>
                </template>
            </el-table-column>

        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pager.page"
            :page-size="pager.limit" layout="total, prev, pager, next,jumper" :total="pager.total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            list: [],
            filterObj: {
                gc_id: 18,
                usr_status: ""
            },
            filterDate: null,
            pager: {
                page: 1,
                limit: 9,
                total: 0
            },
            reqCardTypeObj: {
                gc_type: 3,
                page: 1,
                limit: 1000,
            },
            cardTypeList: [],
            cardSpeciesList: [
                {
                    value: 3,
                    text: "电商礼品卡",
                },
                // {
                //     value: 1,
                //     text: "油卡",
                // },
                // {
                //     value: 2,
                //     text: "话费卡",
                // },

                // {
                //     value: 4,
                //     text: "旅游卡",
                // },
                // {
                //     value: 5,
                //     text: "商超百货卡",
                // },
                // {
                //     value: 6,
                //     text: "餐饮服务",
                // },
            ],
            usr_statusList: [
                {
                    label: "审核中",
                    value: 0,
                },
                {
                    label: "已通过",
                    value: 1,
                },
                {
                    label: "已驳回",
                    value: 2,
                },],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },

        };
    },

    components: {},
    filters: {
        ts2Date: (timestamp) => {
            if (timestamp == 0) {
                return "";
            }
            let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
                Y = date.getFullYear() + "-",
                M =
                    (date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1) + "-",
                D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
                h =
                    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
                    ":",
                m =
                    (date.getMinutes() < 10
                        ? "0" + date.getMinutes()
                        : date.getMinutes()) + ":",
                s =
                    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return Y + M + D + h + m + s;
        },
    },
    mounted() {
        this.getCardTypeList()
    },

    methods: {

        handleSizeChange(val) {
            this.pager.limit = val
            this.getList()

        },
        handleCurrentChange(val) {
            this.pager.page = val
            this.getList()

        },
        async getCardTypeList() {
            let res = await this.$axios
                .get("/api/shop/getGoodsClassList", {
                    params: this.reqCardTypeObj,
                })
            this.cardTypeList = res.data
            this.filterObj.gc_id = this.cardTypeList[0].gc_id
            this.getList()
        },
        async getList() {
            this.loading = true
            let data = {
                ...this.filterObj, ...this.pager,
            }
            if (this.filterDate) {
                data.start_time = this.formatDate(this.filterDate[0]) + " 00:00:01",
                    data.end_time = this.formatDate(this.filterDate[1]) + " 23:59:59"
            }
            let res = await this.$axios
                .get("/api/shop/getSellRecord", {
                    params: data,
                })
            this.loading = false
            this.list = res.data.rows
            this.pager.total = res.data.total
        },
        formatDate(date) {
            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },
    }
}

</script>
<style scoped></style>